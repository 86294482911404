import React from 'react';
import InboxSubMenu from '../Views/Inbox/InboxSubMenu';
import SettingsSideMenu from '../Views/Settings/SettingsSubMenu';
import SubMenuItem, { SubMenuItemIcons } from '../Views/Common/SubMenuItem';
import { RouteProps } from 'react-router-dom';
import { LayoutProps } from '../Layouts/types';

const TermsOfUse = React.lazy(() => import('../Views/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('../Views/PrivacyPolicy'));
const Dpa = React.lazy(() => import('../Views/Dpa'));
const Login = React.lazy(() => import('../Views/Auth/Login'));
const Register = React.lazy(() => import('../Views/Auth/Register'));
const ForgotPassword = React.lazy(() => import('../Views/Auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../Views/Auth/ResetPassword'));

const NewEmail = React.lazy(() => import('../Views/Inbox/NewEmail/NewEmail'));
const NewText = React.lazy(() => import('../Views/Inbox/NewText/NewText'));
const InboxPage = React.lazy(() => import('../Views/Inbox/InboxPage'));

const Leads = React.lazy(
    () => import('../Views/Leads/Dashboard/LeadsDashboard')
);
const AddLead = React.lazy(() => import('../Views/Leads/Addleads/AddLead'));
const LeadsDetail = React.lazy(
    () => import('../Views/Leads/Details/LeadsDetail')
);

const Funnels = React.lazy(
    () => import('../Views/Funnels/FunnelDashboard/FunnelDashboard')
);

const AddUpdateFunnel = React.lazy(
    () => import('../Views/Funnels/AddUpdateFunnel/AddUpdateFunnel')
);

const FunnelTasks = React.lazy(
    () => import('../Views/Funnels/FunnelTasksDashboard/FunnelTasksDashboard')
);
const AddUpdateFunnelTask = React.lazy(
    () => import('../Views/Funnels/AddUpdateFunnelTask/AddUpdateFunnelTask')
);
const FunnelTaskPreview = React.lazy(
    () => import('../Views/Funnels/FunnelTasksDashboard/FunnelTaskPreview')
);

const Tasks = React.lazy(
    () => import('../Views/Tasks/TaskDashboard/TaskDashboard')
);
const AddTask = React.lazy(() => import('../Views/Tasks/AddTask/AddTask'));

const AccountSettings = React.lazy(
    () => import('../Views/Settings/Account/AccountSettings')
);
const SettingsLeadsStatus = React.lazy(
    () => import('../Views/Settings/LeadsStatus/LeadsStatus')
);
const SettingsTemplates = React.lazy(
    () => import('../Views/Settings/MessageTemplates/SettingsTemplates')
);
const AddTextTemplate = React.lazy(
    () =>
        import(
            '../Views/Settings/MessageTemplates/AddTemplates/AddTextTemplate'
        )
);
const AddEmailTemplate = React.lazy(
    () =>
        import(
            '../Views/Settings/MessageTemplates/AddTemplates/AddEmailTemplate'
        )
);

const TemplatePreview = React.lazy(
    () => import('../Views/Settings/MessageTemplates/TemplatePreview')
);

const Integrations = React.lazy(
    () => import('../Views/Settings/Integrations/Integrations')
);

const EmailRouting = React.lazy(
    () => import('../Views/Settings/EmailRouting/EmailRouting')
);

const ImportLeads = React.lazy(
    () => import('../Views/Leads/ImportLeads/ImportLeads')
);

const Subscribers = React.lazy(
    () => import('../Views/Admin/Subscribers/Subscribers')
);
const SubscriberDetails = React.lazy(
    () =>
        import('../Views/Admin/Subscribers/SubscriberDetails/SubscriberDetails')
);
const EditSubscriberDetails = React.lazy(
    () =>
        import(
            '../Views/Admin/Subscribers/SubscriberDetails/EditDetails/EditDetails'
        )
);
const AdminLogin = React.lazy(() => import('../Views/Admin/Auth/AdminLogin'));
const AdminLogs = React.lazy(() => import('../Views/Admin/Logs/Logs'));
const AdminNotifications = React.lazy(
    () => import('../Views/Admin/Notifications/AdminNotifications')
);
const CreateAdminNotification = React.lazy(
    () =>
        import(
            '../Views/Admin/Notifications/CreateAdminNotification/CreateAdminNotification'
        )
);

const Dashboard = React.lazy(() => import('../Views/Dashboard/Dashboard'));
const RegisterBrand = React.lazy(
    () => import('../Views/Settings/A2PRegistration/CreateBrand/CreateBrand')
);

const A2PRegistrations = React.lazy(
    () => import('../Views/Settings/A2PRegistration/A2PRegistrations')
);

const UserNotifications = React.lazy(
    () => import('../Views/Notifications/UserNotifications')
);

const RegisterCampaign = React.lazy(
    () =>
        import(
            '../Views/Settings/A2PRegistration/CreateCampaign/CreateCampaign'
        )
);

const AdminEngagementsPage = React.lazy(
    () => import('../Views/Admin/Engagements/Engagements')
);

const TelemarketerLeads = React.lazy(
    () => import('../Views/Telemarketer/TelemarketerLeads/TelemarketerLeads')
);

const TelemarketerNewLeads = React.lazy(
    () => import('../Views/Telemarketer/TelemarketerLeads/TelemarketerNewLeads')
);

const TelemarketerCallbackLeads = React.lazy(
    () =>
        import(
            '../Views/Telemarketer/TelemarketerLeads/TelemarketerCallbackLeads'
        )
);

const TelemarketerLeadDetails = React.lazy(
    () =>
        import(
            '../Views/Telemarketer/TelemarketerLeads/TelemarketerLeadDetails/TelemarketerLeadDetails'
        )
);

const FreshdeskLogin = React.lazy(
    () => import('../Views/Freshdesk/FreshdeskLogin')
);

type RouteType = RouteProps &
    Partial<{
        layoutProps: LayoutProps;
    }>;

const telemarketerRoutes: RouteType[] = [
    {
        path: '/telemarketer/leads',
        element: <TelemarketerLeads />,
        layoutProps: {
            showSearch: true,
            title: 'Leads',
        },
    },
    {
        path: '/telemarketer/new-leads',
        element: <TelemarketerNewLeads />,
        layoutProps: {
            showSearch: true,
            title: 'New Leads',
        },
    },
    {
        path: '/telemarketer/callback-leads',
        element: <TelemarketerCallbackLeads />,
        layoutProps: {
            showSearch: true,
            title: 'Callback Leads',
        },
    },
    {
        path: '/telemarketer/leads/details/:slug',
        element: <TelemarketerLeadDetails />,
        layoutProps: {
            title: 'Leads Detail',
        },
    },
];

const adminRoutes: RouteType[] = [
    {
        path: 'subscribers',
        element: <Subscribers />,
        layoutProps: {
            title: 'Subscribers',
        },
    },
    {
        path: 'engagements',
        element: <AdminEngagementsPage />,
        layoutProps: {
            title: 'Engagements',
        },
    },
    {
        path: 'subscribers/:id',
        element: <SubscriberDetails />,
        layoutProps: {
            title: 'Subscriber details',
            bgContentTransparent: true,
        },
    },
    {
        path: 'subscribers/edit/:id',
        element: <EditSubscriberDetails />,
        layoutProps: {
            title: 'Edit subscriber details',
        },
    },
    {
        path: 'logs',
        element: <AdminLogs />,
        layoutProps: {
            title: 'Logs',
        },
    },
    {
        path: 'announcements',
        element: <AdminNotifications />,
        layoutProps: {
            title: 'Announcements',
        },
    },
    {
        path: 'announcements/create',
        element: <CreateAdminNotification />,
        layoutProps: {
            title: 'New Announcements',
        },
    },
];

const adminAuthRoutes: RouteType[] = [
    {
        path: '/admin/login',
        element: <AdminLogin />,
        layoutProps: {
            isAdminLayout: true,
        },
    },
];

const loginRoutes: RouteType[] = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/create-account',
        element: <Register />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
];

const noHeaderRoutes: RouteType[] = [
    {
        path: '/terms-of-use',
        element: <TermsOfUse />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/data-processing-addendum',
        element: <Dpa />,
    },
];

const mainRoutes: RouteType[] = [
    {
        path: '/leads',
        element: <Leads />,
        layoutProps: {
            showSearch: true,
            title: 'Leads',
        },
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
        layoutProps: {
            title: 'Dashboard',
            bgContentTransparent: true,
            showGreeting: true,
        },
    },
    {
        path: '/leads/new',
        element: <AddLead />,
        layoutProps: {
            title: 'Leads',
        },
    },
    {
        path: '/leads/detail/:slug',
        element: <LeadsDetail />,
        layoutProps: {
            title: 'Leads Detail',
        },
    },
    {
        path: '/inbox/unified/chat',
        element: <InboxPage type='unified' />,
        layoutProps: {
            title: 'Unified Inbox',
            showSearch: true,
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/unified/chat'
                    icon={SubMenuItemIcons.unified}
                    title='Unified Inbox'
                    hint='All your messages unified'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/inbox/email/chat/:activeChatList?',
        element: <InboxPage type='email' />,
        layoutProps: {
            title: 'Emails',
            showSearch: true,
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/email/chat'
                    icon={SubMenuItemIcons.email}
                    title='Emails'
                    hint='Email correspondence'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/inbox/text/chat/:activeChatList?',
        element: <InboxPage type='text' />,
        layoutProps: {
            title: 'Texts',
            showSearch: true,
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/text/chat'
                    icon={SubMenuItemIcons.text}
                    title='Texts'
                    hint='SMS and MMS messages'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/inbox/trash/chat/:activeChatList?',
        element: <InboxPage type='trash' />,
        layoutProps: {
            title: 'Deleted',
            showSearch: true,
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/trash/chat'
                    icon={SubMenuItemIcons.trash}
                    title='Deleted'
                    hint='Removed messages'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/inbox/email/new/:activeLead?',
        element: <NewEmail />,
        layoutProps: {
            title: 'New Email',
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/email/chat'
                    icon={SubMenuItemIcons.email}
                    title='Emails'
                    hint='Email correspondence'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/inbox/text/new/:activeLead?',
        element: <NewText />,
        layoutProps: {
            title: 'New Text',
            subNavigation: <InboxSubMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/inbox/text/chat'
                    icon={SubMenuItemIcons.text}
                    title='Texts'
                    hint='SMS and MMS messages'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/campaigns',
        element: <Funnels />,
        layoutProps: {
            showSearch: true,
            title: 'Campaigns',
        },
    },
    {
        path: '/campaigns/new',
        element: <AddUpdateFunnel />,
        layoutProps: {
            title: 'Campaigns',
        },
    },
    {
        path: '/campaigns/config/:slug',
        element: <AddUpdateFunnel />,
        layoutProps: {
            title: 'Campaign Configuration',
        },
    },
    {
        path: '/campaigns/copy/:slug',
        element: <AddUpdateFunnel />,
        layoutProps: {
            title: 'Copy campaign',
        },
    },
    {
        path: '/campaigns/events/:slug',
        element: <FunnelTasks />,
        layoutProps: {
            title: 'Campaign Events Setup',
            showSearch: true,
        },
    },
    {
        path: '/campaigns/:slug/events/add/:type',
        element: <AddUpdateFunnelTask />,
        layoutProps: {
            title: 'Add Campaign Event',
        },
    },
    {
        path: '/campaigns/:slug/events/preview/:funnelTaskId',
        element: <FunnelTaskPreview />,
        layoutProps: {
            title: 'Preview Campaign Event',
        },
    },
    {
        path: '/campaigns/:slug/events/edit/:type/:funnelTaskId',
        element: <AddUpdateFunnelTask />,
        layoutProps: {
            title: 'Edit Campaign Event',
        },
    },
    {
        path: '/campaigns/:slug/events/duplicate/:type/:funnelTaskId',
        element: <AddUpdateFunnelTask />,
        layoutProps: {
            title: 'Duplicate Campaign Event',
        },
    },
    {
        path: '/tasks',
        element: <Tasks />,
        layoutProps: {
            showSearch: true,
            title: 'Tasks',
        },
    },
    {
        path: '/tasks/new',
        element: <AddTask />,
        layoutProps: {
            title: 'Tasks',
        },
    },
    {
        path: '/settings/account',
        element: <AccountSettings />,
        layoutProps: {
            title: 'Account Settings',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/account'
                    icon={SubMenuItemIcons.unified}
                    title='Account Settings'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/settings/leads/statuses',
        element: <SettingsLeadsStatus />,
        layoutProps: {
            title: 'Lead Statuses',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/leads/statuses'
                    icon={SubMenuItemIcons.text}
                    title='Lead Statuses'
                    hint='Connected tools and services'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/settings/templates',
        element: <SettingsTemplates />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/settings/templates/:operation/text',
        element: <AddTextTemplate />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/settings/templates/:operation/email',
        element: <AddEmailTemplate />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/settings/templates/:operation/text/:id',
        element: <AddTextTemplate />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/settings/templates/:operation/email/:id',
        element: <AddEmailTemplate />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/settings/templates/preview/:id',
        element: <TemplatePreview />,
        layoutProps: {
            title: 'Email & Text Templates',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/templates'
                    icon={SubMenuItemIcons.email}
                    title='Email & Text Templates'
                    hint='For Campaign steps and lead messages'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/settings/integrations',
        element: <Integrations />,
        layoutProps: {
            title: 'Integrations',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/integrations'
                    icon={SubMenuItemIcons.email}
                    title='Integrations'
                    hint='External services integration summary'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/settings/email-routing',
        element: <EmailRouting />,
        layoutProps: {
            title: 'Email Routing Settings',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/email-routing'
                    icon={SubMenuItemIcons.email}
                    title='Email Routing Settings'
                    hint='Public e-mail addresses'
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/leads/import',
        element: <ImportLeads />,
        layoutProps: {
            title: 'Import Leads',
        },
    },
    {
        path: '/settings/a2p-registrations/brand',
        element: <RegisterBrand />,
        layoutProps: {
            title: 'A2P Registration',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/a2p-registrations/brand'
                    icon={SubMenuItemIcons.phone}
                    title='Register Brand'
                    hint=''
                    noPadding={true}
                />
            ),
        },
    },

    {
        path: '/settings/a2p-registrations',
        element: <A2PRegistrations />,
        layoutProps: {
            title: 'A2P Registrations',
            bgContentTransparent: true,
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/a2p-registrations'
                    icon={SubMenuItemIcons.phone}
                    title='A2P Registrations'
                    hint=''
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/announcements',
        element: <UserNotifications />,
        layoutProps: {
            title: 'Announcements Inbox',
        },
    },

    {
        path: '/settings/a2p-registrations/campaign',
        element: <RegisterCampaign />,
        layoutProps: {
            title: 'A2P Registration',
            subNavigation: <SettingsSideMenu />,
            mobileSubMenuHeader: (
                <SubMenuItem
                    to='/settings/a2p-registrations/campaign'
                    icon={SubMenuItemIcons.phone}
                    title='Register campaign'
                    hint=''
                    noPadding={true}
                />
            ),
        },
    },
    {
        path: '/freshdesk/login',
        element: <FreshdeskLogin />,
        layoutProps: {},
    },
];

export {
    noHeaderRoutes,
    loginRoutes,
    mainRoutes,
    adminRoutes,
    adminAuthRoutes,
    telemarketerRoutes,
};
